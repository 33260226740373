import { useAccount } from "src/providers/accountProvider";
import useTTRouter from "src/router/hooks/useTTRouter";
import { Sheet } from "react-modal-sheet";
import { useState } from "react";
import { RewardsSpinner } from "src/components/RewardsSpinner";
import { Capacitor } from "@capacitor/core";
import { GradientBorder } from "src/components/ui/GradientContainer";

export function Header() {
  const { balance, account } = useAccount();
  const { navigateToMyProfile } = useTTRouter();

  const [isRewardsOpen, setIsRewardsOpen] = useState(false);

  function handleRewardsClose() {
    setIsRewardsOpen(false);
  }

  const paddingTop = Capacitor.getPlatform() === "ios" ? "pt-18" : "pt-12";

  return (
    <>
      <div className="relative w-full h-full ">
        <div
          className={`${paddingTop} absolute w-full h-full backdrop-blur-xl`}
        ></div>
        <div
          className={`${paddingTop} flex flex-row relative bg-gradient-to-br to-white/10 via-[#34414d]/10 from-[#34414d]/10 border-hairline border-[#34414d]/30 px-3 pb-1 space-y-2`}
        >
          <button
            className="w-12 h-10 mr-2"
            onClick={() => navigateToMyProfile()}
          >
            <GradientBorder rounded="rounded-full">
              <img
                src={account?.profileImage ?? ""}
                alt=""
                className="size-10 rounded-full"
              />
            </GradientBorder>
          </button>
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-row">
              <img src="/icons/trophy.webp" alt="" className="size-6" />
              <span className="font-medium text-white ml-1 inline-block text-lg align-text-bottom">
                {balance.trophies}
              </span>
            </div>

            <div className="flex flex-row">
              <img src="/icons/ttoken.webp" alt="" className="size-6" />

              {/* <TTokensLogo className="w-4" /> */}
              <span className="font-medium text-white ml-1 inline-block text-lg align-text-bottom">
                {balance.ticTapsTokens}
              </span>
            </div>

            <div className="flex flex-row mr-1">
              <img src="/icons/coin.webp" alt="" className="size-6" />
              {/* <CoinIcon className="w-4" /> */}
              <span className="font-medium text-white ml-1 inline-block text-lg align-text-bottom">
                {balance.dollars}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Sheet isOpen={isRewardsOpen} onClose={() => handleRewardsClose()}>
        <Sheet.Container>
          <Sheet.Header className="bg-tic-taps-grey" />
          <Sheet.Content className="bg-tic-taps-grey p-0">
            <RewardsSpinner onComplete={() => handleRewardsClose()} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop className="bg-tic-taps-grey" />
      </Sheet>
    </>
  );
}
