import { TrophyIcon } from "@heroicons/react/24/solid";
import { Participant } from "src/types";
import { GradientBorder } from "src/components/ui/GradientContainer";

export default function Place({
  score,
  place,
}: Readonly<{
  score?: Participant;
  place: 1 | 2 | 3;
}>) {
  const profilePicture = score ? score.profile.profileImage : "/user.jpg";
  const name = score ? score.profile.gamertag : "";
  const trophies = score ? score.profile.xp : "";
  const highscore = score ? score.highSchore : "";

  const placeStyles = {
    1: {
      shadowColor: "shadow-[0_0_10px_5px_rgba(255,215,0,0.4)]",
      bgColor: "bg-yellow-400",
      textColor: "text-yellow-400",
    },
    2: {
      shadowColor: "shadow-[0_0_10px_5px_rgba(192,192,192,0.2)]",
      bgColor: "bg-gray-500",
      textColor: "text-gray-500",
    },
    3: {
      shadowColor: "shadow-[0_0_10px_5px_rgba(255,165,0,0.3)]",
      bgColor: "bg-orange-500",
      textColor: "text-orange-500",
    },
  };

  const { shadowColor, bgColor, textColor } = placeStyles[place];

  return (
    <div className="flex flex-col items-center py-2 px-2">
      <GradientBorder
        from="#FF5E00"
        to="#FFAA00"
        via={["#FFAA00"]}
        rounded="rounded-full"
        padding="p-0.5"
      >
        <div
          className={`overflow-hidden ${shadowColor} rounded-full ${
            place === 1 ? "w-32" : "w-20"
          } bg-blue-500/30 h-fit ${!score ? "opacity-40" : ""}`}
        >
          <img
            src={profilePicture}
            alt="Profile"
            className={`${!score ? "opacity-50" : ""}`}
          />
        </div>
      </GradientBorder>
      <div
        className={`${bgColor} rounded-full px-2 w-fit -mt-2 ${shadowColor} ${
          !score ? "opacity-50" : ""
        }`}
      >
        <p className="font-semibold text-white">{place}</p>
      </div>
      <div className="flex flex-col space-y-1 items-center p-2">
        {score ? (
          <>
            <p className="text-sm font-semibold text-white max-w-24 rounded-lg truncate">
              {name}
            </p>
            <p
              className={`text-sm font-bold ${textColor} max-w-12 h-4 rounded-lg`}
            >
              {highscore}
            </p>{" "}
            <p className="text-sm font-semibold text-orange-500 text-center items-center max-w-12 h-4 rounded-lg">
              {trophies} <TrophyIcon className="w-3 h-3 inline mb-0.5" />
            </p>
          </>
        ) : (
          <>
            <p className="bg-zinc-900 w-16 h-4 rounded-lg opacity-40"></p>
            <p className="bg-zinc-900 w-12 h-4 rounded-lg opacity-40"></p>
            <p className="bg-zinc-900 w-12 h-4 rounded-lg opacity-40"></p>
          </>
        )}
      </div>
    </div>
  );
}
