import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, ReactNode, useMemo } from "react";
import { Participant, Party } from "../types";
import GetParty from "../api/party/getParty";
import { useAccount } from "./accountProvider";
import GetParticipants from "../api/party/participants/getParticipants";

import GetLeaderboard from "../api/party/leaderboard/getLeaderboard";
import JoinPartyById from "../api/party/controls/joinPartyById";
import LeavePartyById from "../api/party/controls/leavePartyById";
import { SaveHighscore } from "../api/party/leaderboard/saveHighscore";
import EndParty from "../api/party/controls/endParty";

interface PartyContextType {
  party: Party | undefined;
  isPartyQueryLoading: boolean;

  endPartyById: (party: Party) => void;
  joinPartyById: (party: Party) => void;
  leavePartyById: (party: Party) => void;

  participants: Participant[];
  isParticipantsLoading: boolean;
  refreshParticipants: () => void;

  isAccountParticipant: boolean;
  isAccountOwner: boolean;

  leaderBoard: Participant[];
  submitHighscore: ({ score }: { score: number }) => void;
  isLeaderboardQueryLoading: boolean;

  refreshParty: () => void;
  refreshLeaderboard: () => void;

  isJoinPartyLoading: boolean;
  isLeavePartyLoading: boolean;
  isSubmitHighscoreLoading: boolean;
  isEndPartyLoading: boolean;
}

const PartyContext = createContext<PartyContextType | undefined>(undefined);

export function PartyProvider({
  children,
  partyId,
}: Readonly<{
  children: ReactNode;
  partyId: string;
}>) {
  const qClient = useQueryClient();
  const { account, refreshBalances } = useAccount();
  const key = ["party", partyId];

  const partyQuery = useQuery({
    queryKey: key,
    queryFn: async () => await GetParty(partyId),
  });

  const participantsQuery = useQuery({
    queryKey: ["participants", partyId],
    refetchOnMount: "always",
    queryFn: async () => await GetParticipants(partyId),
  });

  const leaderboardQuery = useQuery({
    queryKey: ["highscore", partyId],
    refetchOnMount: "always",
    queryFn: async () => {
      return await GetLeaderboard(partyId);
    },
  });

  const joinPartyByIdMutation = useMutation({
    mutationFn: async (party: Party) => {
      await JoinPartyById(
        party.id,
        party.inviteCode,
        party.buyInAmount,
        party.currencyType,
        party.currencyCode
      );
      qClient.refetchQueries({ queryKey: ["participants", party.id] });
    },
  });

  const leavePartyByIdMutation = useMutation({
    mutationFn: async (party: Party) => {
      await LeavePartyById(party.id);
      qClient.invalidateQueries({ queryKey: ["participants", party.id] });
    },
  });

  const submitHighscoreMutation = useMutation({
    mutationFn: async ({ score }: { score: number }) => {
      const partyId = partyQuery.data?.id;
      const data = await SaveHighscore(partyId!, score, score);
      qClient.invalidateQueries({
        queryKey: ["highscore", partyQuery.data?.id ?? ""],
      });
      return data;
    },
  });

  const endPartyMutation = useMutation({
    mutationFn: async (party: Party) => {
      const data = await EndParty(party.id);
      qClient.invalidateQueries({
        queryKey: key,
      });
      refreshBalances();
      return data;
    },
  });

  function refreshParty() {
    qClient.refetchQueries({
      queryKey: key,
    });
  }

  const isJoinPartyLoading = joinPartyByIdMutation.isPending;
  const isLeavePartyLoading = leavePartyByIdMutation.isPending;
  const isSubmitHighscoreLoading = submitHighscoreMutation.isPending;
  const isEndPartyLoading = endPartyMutation.isPending;
  const isParticipantsLoading = participantsQuery.isLoading;

  const isAccountParticipant =
    !!account?.id &&
    !!participantsQuery.data &&
    !!participantsQuery.data.find((p: any) => p.id == account.id);

  const isAccountOwner = partyQuery.data?.createdById === account?.id;

  const props: PartyContextType = useMemo(() => {
    return {
      party: partyQuery.data,
      isPartyQueryLoading: partyQuery.isLoading || partyQuery.isFetching,
      isLeaderboardQueryLoading: leaderboardQuery.isLoading,
      isAccountParticipant,
      isAccountOwner,
      endPartyById: endPartyMutation.mutate,
      joinPartyById: joinPartyByIdMutation.mutate,
      leavePartyById: leavePartyByIdMutation.mutate,
      participants: participantsQuery.data ?? [],
      isParticipantsLoading,
      refreshParticipants: () => participantsQuery.refetch(),
      refreshParty,
      refreshLeaderboard: () =>
        qClient.refetchQueries({
          queryKey: ["highscore", partyId],
        }),
      isJoinPartyLoading: isJoinPartyLoading,
      isLeavePartyLoading: isLeavePartyLoading,
      isSubmitHighscoreLoading,
      isEndPartyLoading,
      leaderBoard: leaderboardQuery.data ?? [],
      submitHighscore: submitHighscoreMutation.mutate,
    };
  }, [
    partyQuery.data,
    partyQuery.isLoading,
    partyQuery.isFetching,
    leaderboardQuery.isLoading,
    leaderboardQuery.data,
    isAccountParticipant,
    isAccountOwner,
    endPartyMutation.mutate,
    joinPartyByIdMutation.mutate,
    leavePartyByIdMutation.mutate,
    participantsQuery,
    isParticipantsLoading,
    isJoinPartyLoading,
    isLeavePartyLoading,
    isSubmitHighscoreLoading,
    isEndPartyLoading,
    submitHighscoreMutation.mutate,
    qClient,
    partyId,
  ]);

  return (
    <PartyContext.Provider value={props}>{children}</PartyContext.Provider>
  );
}
export function usePartyParticipants() {
  const context = useContext(PartyContext);
  if (context === undefined) {
    throw new Error("usePartyParticipants must be used within a PartyProvider");
  }
  return {
    participants: context.participants,
    isParticipantsLoading: context.isParticipantsLoading,
    refreshParticipants: context.refreshParticipants,
  };
}

export function usePartyLeaderboard() {
  const context = useContext(PartyContext);
  if (context === undefined) {
    throw new Error("usePartyLeaderboard must be used within a PartyProvider");
  }
  return {
    leaderBoard: context.leaderBoard,
    isLeaderboardQueryLoading: context.isLeaderboardQueryLoading,
    refreshLeaderboard: context.refreshLeaderboard,
  };
}

// Hook to use the PartyContext
export function useParty() {
  const context = useContext(PartyContext);
  if (context === undefined) {
    throw new Error("useParty must be used within a PartyProvider");
  }
  return {
    party: context.party,
    refreshParty: context.refreshParty,
    isPartyQueryLoading: context.isPartyQueryLoading,

    isAccountOwner: context.isAccountOwner,
    isAccountParticipant: context.isAccountParticipant,

    endPartyById: context.endPartyById,
    isEndPartyLoading: context.isEndPartyLoading,
    joinPartyById: context.joinPartyById,
    isJoinPartyLoading: context.isJoinPartyLoading,
    leavePartyById: context.leavePartyById,
    isLeavePartyLoading: context.isLeavePartyLoading,

    submitHighscore: context.submitHighscore,
    isSubmitHighscoreLoading: context.isSubmitHighscoreLoading,
  };
}
