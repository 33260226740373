import { useAccount } from "src/providers/accountProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faStar,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as PartyScoreBackground } from "src/assets/partyScoreButtonBackground.svg";
import BlurredCircleBackground from "src/components/backgrounds/BlurredCircleBackground";
import { GradientBorder } from "src/components/ui/GradientContainer";
import {
  useBubblesBackground,
  useSecondaryHeader,
} from "src/providers/layoutProvider";
import { PartyHistoryEntry } from "src/types";
import useTTRouter from "src/router/hooks/useTTRouter";
import PullToRefresh from "react-simple-pull-to-refresh";
import { LoadingSpinner } from "src/components/ui/LoadingOverlay";

export enum PartyHistoryOutcome {
  WINNER = "WINNER",
  LOSER = "LOSER",
  DID_NOT_PLAY = "DID_NOT_PLAY",
  UNKNOWN = "UNKNOWN",
}
function groupByDate(data: PartyHistoryEntry[]) {
  const groupedData = data.reduce<Record<string, PartyHistoryEntry[]>>(
    (accumulator, current) => {
      const date = current.date;
      if (!accumulator[date]) {
        accumulator[date] = [];
      }
      accumulator[date].push(current);
      return accumulator;
    },
    {}
  );

  return Object.keys(groupedData)
    .sort()
    .map((date) => ({
      date,
      entries: groupedData[date],
    }));
}

export function AccountHistoryPage() {
  useSecondaryHeader("Results");
  useBubblesBackground();

  const { navigateToParty } = useTTRouter();
  const {
    account,
    isProfileHistoryLoading,
    profileHistory,
    refreshHistory,
    refreshBalances,
  } = useAccount();

  const groupedHistory = groupByDate(profileHistory?.parties ?? []);

  function handlePartyClick(party: PartyHistoryEntry) {
    navigateToParty(party.id);
  }

  function handleRefresh() {
    refreshHistory();
    refreshBalances();
  }

  return (
    <PullToRefresh onRefresh={async () => handleRefresh()}>
      <div className="flex flex-col items-center justify-center pt-16 z-[10] w-full md:w-2/3 lg:w-1/2 mx-auto">
        <div className="w-32 h-32">
          <GradientBorder padding="p-0.5" rounded="rounded-full">
            <div className="rounded-2xl overflow-hidden w-full h-full z-[10]">
              <img
                src={
                  account?.profileImage ??
                  "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                }
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
          </GradientBorder>
        </div>

        <div className="z-10 w-full pt-4 flex flex-col items-center justify-center space-y-2">
          <p className="text-xl font-semibold  text-white">
            {account?.gamerTag}
          </p>

          <div className="flex items-center space-x-1 bg-gray-500/20 backdrop-blur-lg py-1 px-2 rounded-lg">
            <p className="text-white">Win Ratio</p>
            <p className="text-white">0%</p>
          </div>
        </div>

        {isProfileHistoryLoading && <LoadingSpinner />}
        <div className="flex flex-col w-full p-4 space-y-4 z-[10]">
          {groupedHistory
            .sort((a, b) => b.date.localeCompare(a.date))
            .map(({ date, entries }) => (
              <Section
                title={date}
                entries={entries}
                onPartyClick={handlePartyClick}
              />
            ))}
        </div>
      </div>
    </PullToRefresh>
  );
}

function Section({
  title,
  entries,
  onPartyClick,
}: {
  title: string;
  entries: PartyHistoryEntry[];
  onPartyClick: (entry: PartyHistoryEntry) => void;
}) {
  return (
    <>
      <p className="text-white font-semibold z-[10]">{title}</p>
      {entries.map((e) => (
        <Entry
          imageURL={e.game.icon}
          title={e.outcome}
          outcome={e.outcome}
          points={e.score}
          onClick={() => onPartyClick(e)}
        />
      ))}
    </>
  );
}

function Entry({
  outcome,
  title,
  imageURL,
  points,
  onClick,
}: {
  title: string;
  imageURL: string;
  points: string;
  outcome: PartyHistoryOutcome;
  onClick: () => void;
}) {
  switch (outcome) {
    case PartyHistoryOutcome.DID_NOT_PLAY:
      return (
        <WaitingEntry
          onClick={() => onClick()}
          title={title}
          imageURL={imageURL}
          points={points}
        />
      );
    case PartyHistoryOutcome.UNKNOWN:
      return (
        <WaitingEntry
          onClick={() => onClick()}
          title={title}
          imageURL={imageURL}
          points={points}
        />
      );
    case PartyHistoryOutcome.LOSER:
      return (
        <LossEntry
          onClick={() => onClick()}
          title={title}
          imageURL={imageURL}
          points={points}
        />
      );
    case PartyHistoryOutcome.WINNER:
      return (
        <WinEntry
          onClick={() => onClick()}
          title={title}
          imageURL={imageURL}
          points={points}
        />
      );
  }
  return <></>;
}

function WinEntry({
  title,
  imageURL,
  points,
  onClick,
}: {
  title: string;
  imageURL: string;
  points: string;
  onClick: () => void;
}) {
  return (
    <button onClick={() => onClick()}>
      <GradientBorder rounded="rounded-2xl">
        <div className="relative border-gray-300/30 overflow-hidden flex w-full justify-between items-center shadow-lg border border-[#2b3740] rounded-2xl py-2 px-2 bg-[#1c2833]">
          <div className="flex items-center space-x-4">
            <img
              className="w-16 h-16 rounded-2xl object-cover"
              src={imageURL}
              alt="Profile"
            />

            <div className="relative w-fit h-fit flex items-center justify-center">
              <p className="absolute text-white font-bold text-sm">1</p>
              <FontAwesomeIcon
                opacity={0.5}
                color="#ffd700"
                size="2x"
                icon={faStar}
                className="relative z-10"
              />
            </div>

            <p className="text-yellow-400 font-semibold tracking-wide text-lg">
              {title}
            </p>
          </div>

          <p className="text-yellow-400 text-2xl tracking-wide font-semibold z-30">
            {points}
          </p>

          <div className="absolute top-0 -right-20 z-10 w-fit">
            <PartyScoreBackground />
          </div>

          <div className="absolute top-0 right-0 z-0 opacity-70">
            <BlurredCircleBackground
              gradientName="winHistoryEntry"
              gradient={["#00FFAA", "#4579F5", "#9C42F5"]}
            />
          </div>
          <div className="absolute top-0 -right-20 z-0 opacity-60">
            <BlurredCircleBackground
              gradientName="winHistoryEntry2"
              gradient={["#6A0DAD", "#8A2BE2", "#B57EDC"]}
            />
          </div>
        </div>
      </GradientBorder>
    </button>
  );
}

function LossEntry({
  title,
  imageURL,
  points,
  onClick,
}: {
  title: string;
  imageURL: string;
  points: string;
  onClick: () => void;
}) {
  return (
    <button onClick={() => onClick()}>
      <GradientBorder
        rounded="rounded-2xl"
        from="#FF5E00"
        to="#C32DE1"
        via={["#F54562"]}
      >
        <div className="relative overflow-hidden flex w-full justify-between items-center shadow-xl border border-[#2b3740] rounded-2xl p-2 bg-[#1c2833]">
          <div className="flex items-center space-x-4">
            <img
              className="w-16 h-16 rounded-2xl object-cover"
              src={imageURL}
              alt="Profile"
            />

            <div className="flex items-center space-x-2">
              <FontAwesomeIcon
                opacity={0.8}
                color="#f0426d"
                size="2x"
                icon={faTriangleExclamation}
                className="relative z-10"
              />
              <p className="text-[#f0426d] tracking-wide px-2 font-bold text-lg">
                Loss!
              </p>
            </div>
          </div>
          <div className="absolute top-0 right-0 z-0 opacity-70">
            <BlurredCircleBackground
              gradientName="lossHistoryEntry"
              gradient={["#FF5E00", "#F54562", "#C32DE1"]}
            />
          </div>
        </div>
      </GradientBorder>
    </button>
  );
}

function WaitingEntry({
  title,
  imageURL,
  points,
  onClick,
}: {
  title: string;
  imageURL: string;
  points: string;
  onClick: () => void;
}) {
  return (
    <button onClick={() => onClick()}>
      <GradientBorder from="#FF5E00" to="#FFAA00" via={["#FFAA00"]}>
        <div className="relative overflow-hidden flex w-full justify-between items-center shadow-xl border-hairline border-[#34414d] rounded-xl p-2">
          <div className="flex items-center space-x-2">
            <img className="w-16 rounded-lg" src={imageURL} />

            <FontAwesomeIcon
              opacity={0.7}
              color="#FFAA00"
              size="2x"
              icon={faListCheck}
            />
            <p className="text-[#FFAA00] font-semibold">Waiting...</p>
          </div>

          <div className="absolute top-0 right-0 z-10">
            <BlurredCircleBackground
              gradientName="lossHistoryEntry"
              gradient={["#FF5E00", "#FFAA00", "#FFAA00"]}
            />
          </div>
        </div>
      </GradientBorder>
    </button>
  );
}
