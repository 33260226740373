import { GradientBorder } from "../ui/GradientContainer";
import { Game, PartyHistoryEntry } from "src/types";
import LoadingRing from "../ui/loadingRing";

function getOutcomeTextAndEmoji(outcome: string) {
  switch (outcome) {
    case "WINNER":
      return "Won 😎";
    case "LOSER":
      return "Lost 😢";
    case "DID_NOT_PLAY":
      return "Not Played 💤";
    default:
      return "";
  }
}

export default function PartyHistoryCard({
  game,
  onClick,
  party,
}: Readonly<{
  game: Game;
  onClick: () => void;
  party: PartyHistoryEntry;
}>) {
  return (
    <GradientBorder
      from={
        party.outcome === "WINNER"
          ? "#00FFAA"
          : party.outcome === "LOSER"
          ? "#FF5E00"
          : undefined
      }
      to={
        party.outcome === "WINNER"
          ? "#9C42F5"
          : party.outcome === "LOSER"
          ? "#FFAA00"
          : undefined
      }
      via={
        party.outcome === "WINNER"
          ? ["#4579F5"]
          : party.outcome === "LOSER"
          ? ["#FFAA00"]
          : undefined
      }
      padding="p-1"
    >
      <div className="relative h-44 max-w-40 min-w-40 bg-neutral-700 rounded-xl overflow-hidden">
        <img src={game.icon} alt="" />

        <div className="absolute top-0 w-full">
          <div className="w-full flex justify-between p-1 gap-x-2">
            <div className="bg-gray-900/70 text-yellow-500 w-full font-semibold text-xs rounded-lg px-2 flex items-center backdrop-blur-sm">
              <p className="text-white font-semibold text-xs">
                {party.game.name}
              </p>
            </div>
            <div className="flex p-1 items-center gap-x-1 rounded-lg bg-gray-900/70 backdrop-blur-sm w-1/3">
              <img className="size-4" src="/icons/trophy.webp" />
              {party.score ? (
                <p className="text-white font-semibold text-xs">
                  {party.score}
                </p>
              ) : (
                <LoadingRing size={8} stroke={2} />
              )}
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 w-full px-1 space-y-1 pb-1">
          <div className="h-fit bg-gray-900/70 w-full rounded-lg px-2 py-1 flex flex-row justify-between backdrop-blur-sm">
            <p className="text-gray-400 font-semibold text-xs">Outcome</p>
            <p className="text-white font-semibold text-xs">
              {getOutcomeTextAndEmoji(party.outcome)}
            </p>
          </div>
        </div>
      </div>
    </GradientBorder>
  );
}
