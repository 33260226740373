import { Participant, Party } from "../types";
import { useState } from "react";
import { GradientBorder } from "./ui/GradientContainer";
import { useAccount } from "../providers/accountProvider";
import { Transition } from "@headlessui/react";
import useTTRouter from "src/router/hooks/useTTRouter";
import { IoShareSocial } from "react-icons/io5";
import { Sheet } from "react-modal-sheet";
import QRCode from "react-qr-code";
import { Share } from "@capacitor/share";

export default function ParticipantsOverview({
  participants,
  party,
  isParticipantsLoading,
  refreshParticipants,
  seeParticipantsProfile,
}: Readonly<{
  participants: Participant[] | undefined;
  isParticipantsLoading: boolean;
  party: Party;
  refreshParticipants: () => void;
  seeParticipantsProfile: (participant: Participant) => void;
}>) {
  const [seeAllParticipants, setSeeAllParticipants] = useState(false);
  const { account } = useAccount();
  const { navigateToMyProfile } = useTTRouter();
  const [shareSheetOpen, setShareSheetOpen] = useState(false);

  const currentUrl = window.location.href;
  const baseUrl = currentUrl.slice(0, currentUrl.lastIndexOf("/party"));
  const partyUrl = baseUrl && party?.id ? `${baseUrl}/party/${party.id}` : "";

  const handleShare = async () => {
    try {
      await Share.share({
        title: "Join the party!",
        text: `Join our party using this invite code: ${party.inviteCode}`,
        url: partyUrl,
        dialogTitle: "Share Party Invite",
      });
    } catch (error) {
      console.error("Error sharing content:", error);
    }
  };


  if (!participants) {
    return (
      <div className="flex justify-center items-center h-[600px]">
        <p className="text-gray-400">Unable to load participants.</p>
      </div>
    );
  }

  return (
    <>
      <Transition
        show={isParticipantsLoading}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 flex justify-center items-center bg-opacity-50"></div>
      </Transition>

      <Transition
        show={
          !isParticipantsLoading && !!participants && participants.length > 0
        }
        enter="transition-all duration-300 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition-all duration-300 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="flex flex-col gap-x-2 h-84 py-4">
          <div className="flex flex-row justify-between gap-x-12 px-4">
            <div className="w-1/2 flex flex-row items-center gap-x-2">
              <p className="text-white font-semibold text-lg">Party people</p>
              <p className="text-gray-300 text-lg">({participants?.length}):</p>
            </div>
            <button
              className="h-fit"
              onClick={(event) => {
                event.stopPropagation();
                setShareSheetOpen(true);
              }}
            >
              <IoShareSocial size={28} color="lightgray" />
            </button>
          </div>

          {!seeAllParticipants && (
            <div className="flex flex-col pt-2 space-y-2 w-full">
              {participants?.map((participant) => (
                <button
                  key={participant.profile.id}
                  onClick={() =>
                    account?.id === participant.profile.id
                      ? navigateToMyProfile()
                      : seeParticipantsProfile(participant)
                  }
                  className="bg-[#1f2328] rounded-3xl p-3 border border-[#333a42] flex items-center justify-between shadow-lg hover:shadow-xl transition-all duration-300"
                >
                  <div className="relative flex items-center">
                    <div className="flex items-center space-x-2">
                      {account?.id === participant.profile.id ? (
                        <GradientBorder
                          from="#FF5E00"
                          to="#FFAA00"
                          via={["#FFAA00"]}
                          padding="p-0.5"
                          rounded="rounded-full"
                        >
                          <img
                            src={participant.profile.profileImage}
                            alt={`${participant.profile.gamertag} Profile`}
                            className="rounded-full h-12 w-12 object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                        </GradientBorder>
                      ) : (
                        <GradientBorder padding="p-0.5" rounded="rounded-full">
                          <img
                            src={participant.profile.profileImage}
                            alt={`${participant.profile.gamertag} Profile`}
                            className="rounded-full h-12 w-12 object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                        </GradientBorder>
                      )}
                      <div className="flex flex-col items-start">
                        <p className="text-white truncate max-w-40 font-semibold text-lg tracking-wide">
                          {participant.profile.gamertag}
                        </p>
                        <div className="flex space-x-1 items-center text-center">
                          <img className="size-4" src="/icons/trophy.webp" />
                          <p className="text-md text-orange-500 font-bold">
                            {participant.profile.xp}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-center">
                    <div className=" flex items-center justify-center text-lg text-yellow-500 font-bold">
                      {participant.highSchore}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      </Transition>

      <Transition
        show={!isParticipantsLoading && participants?.length === 0}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex flex-col items-center p-2 justify-center h-full rounded-xl bg-gradient-to-br from-[#31353b] to-[#181b21] border border-[#34414d]">
          <p className="text-xs text-gray-400">
            You don't have a single person yet
          </p>
          <div className="h-42">
            <img src="people.svg" alt="People" className="h-full" />
          </div>
        </div>
      </Transition>

      <Sheet
        isOpen={shareSheetOpen}
        onClose={() => setShareSheetOpen(false)}
        detent="content-height"
        className="relative z-[80]"
      >
        <Sheet.Container>
          <Sheet.Header className="bg-tic-taps-grey" />
          <Sheet.Content className="bg-tic-taps-grey flex flex-row items-center">
            <div className="flex flex-col justify-around align-center content-center p-8 ">
              <div className="bg-white p-2 rounded-lg">
                <QRCode
                  size={256}
                  style={{ height: "75%" }}
                  value={partyUrl}
                  viewBox={`0 0 256 256`}
                />
              </div>

              <div className="flex flex-row align-center justify-center my-5 space-x-1">
                {party && (
                  <>
                    <p className="text-white text-2xl font-semibold">
                      Invite Code:{" "}
                    </p>
                    <p className="text-white text-2xl">{party.inviteCode}</p>
                  </>
                )}
              </div>

              <button
                className="text-white h-12 font-semibold bg-gradient-to-br from-[#00FFAA] via-[#4579F5] to-[#9C42F5] w-full rounded-lg px-2 py-1"
                onClick={handleShare}
              >
                Share
              </button>
            </div>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </>
  );
}
