import { Post } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function JoinPartyById(
  partyId: string,
  inviteCode: string,
  buyInAmount: number,
  currencyType: string,
  currencyCode: string
): Promise<string> {
  const result = await Post(routes.joinPartyById(), {
    party_id: partyId,
    buy_in_amount: buyInAmount,
    currency_type: currencyType,
    currency_code: currencyCode,
    invite_code: inviteCode,
  });

  return result.party_id;
}
