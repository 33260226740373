import { IoChatbubble } from "react-icons/io5";
import {
  PartyProvider,
  useParty,
  usePartyParticipants,
} from "src/providers/partyProvider";
import { useParams } from "react-router-dom";
import { useState } from "react";
import GradientContainer from "src/components/ui/GradientContainer";
import PartyControls from "src/components/partyControls";
import ParticipantsOverview from "src/components/ParticipantsOverview";
import { useAccount } from "src/providers/accountProvider";
import useTTRouter from "src/router/hooks/useTTRouter";
import { Participant } from "src/types";
import PullToRefresh from "react-simple-pull-to-refresh";
import { usePartyBackground } from "src/providers/layoutProvider";
import { LoadingOverlay } from "src/components/ui/LoadingOverlay";

export function PartyPage() {
  const { partyId } = useParams();

  if (!partyId) {
    return <></>;
  }

  return (
    <PartyProvider partyId={partyId}>
      <_Party />
    </PartyProvider>
  );
}

function _Party() {
  const {
    party,
    isPartyQueryLoading,
    refreshParty,
    joinPartyById,
    leavePartyById,
    endPartyById,
    isEndPartyLoading,
    isJoinPartyLoading,
    isLeavePartyLoading,
  } = useParty();

  const { participants, isParticipantsLoading, refreshParticipants } =
    usePartyParticipants();

  const { account } = useAccount();
  const {
    navigateToPartyChat,
    navigateToLeaderboard,
    navigateToGame,
    navigateToUserProfile,
  } = useTTRouter();

  const { refreshBalances } = useAccount();

  const [isParticipantChanging, setIsParticipantChanging] = useState(false);

  const gameIcon = party?.game?.icon ?? "default-icon-url";
  usePartyBackground(gameIcon);

  const isPartyOfUser =
    !isPartyQueryLoading && account?.id === party?.createdById;

  const isParticipant =
    !isPartyQueryLoading &&
    !!participants?.find((p) => p.profile.id === account?.id);

  const hasPlayedAlready = !!participants?.find(
    (p) => p.profile.id === account?.id
  )?.hasPlayed;

  const isPartyReady = !isPartyQueryLoading && !!party;

  const isLoading =
    isEndPartyLoading || isJoinPartyLoading || isLeavePartyLoading;

  function handleChatRedirect() {
    navigateToPartyChat(party?.chatChannelId ?? "");
  }

  function handleLeaderboardRedirect() {
    navigateToLeaderboard(party?.id ?? "");
  }

  function handleNavigateToGame() {
    const gameId = party?.game.id;
    const partyId = party?.id;
    if (!gameId || !partyId) return;
    navigateToGame(party.game.id, party.id);
  }

  async function handleJoinPartyById() {
    if (!party) return;
    setIsParticipantChanging(true);
    try {
      await joinPartyById(party);
      await refreshParticipants();
    } finally {
      setIsParticipantChanging(false);
    }
  }

  async function handleLeavePartyById() {
    if (!party) return;
    setIsParticipantChanging(true);
    try {
      await leavePartyById(party);
      await refreshParticipants();
    } finally {
      setIsParticipantChanging(false);
    }
  }

  function closePartyById() {
    if (!party) return;
    endPartyById(party);
  }

  async function handleRefresh() {
    setIsParticipantChanging(true);
    refreshParty();
    refreshParticipants();
    refreshBalances();
    setIsParticipantChanging(false);
  }

  if (isPartyQueryLoading) {
    return (
      <div className="flex justify-center items-center h-[600px]">
        <LoadingOverlay description="Loading party..." />
      </div>
    );
  }

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <>
        <div className="relative bg-cover bg-center flex justify-center pb-60">
          <div className="w-full md:w-2/3 lg:w-1/2 h-full gap-y-5 p-4">
            <div className="flex flex-row items-center gap-x-2 py-2 ">
              {party && (
                <p className="text-white font-extrabold text-4xl">
                  {party.name}
                </p>
              )}
            </div>
            {!!party &&
              (isPartyReady ? (
                <PartyControls
                  party={party}
                  isOwner={isPartyOfUser}
                  isParticipant={isParticipant}
                  hasPlayedAlready={hasPlayedAlready}
                  joinParty={handleJoinPartyById}
                  leaveParty={handleLeavePartyById}
                  startGame={handleNavigateToGame}
                  openLeaderboard={handleLeaderboardRedirect}
                  endParty={closePartyById}
                  isLoading={
                    isParticipantsLoading || isParticipantChanging || isLoading
                  }
                />
              ) : (
                <div className="loading-placeholder">
                  Loading Party Controls...
                </div>
              ))}
            <div>
              {!!party && (
                <ParticipantsOverview
                  party={party}
                  seeParticipantsProfile={(participant: Participant) =>
                    navigateToUserProfile(participant.profile.id)
                  }
                  refreshParticipants={refreshParticipants}
                  isParticipantsLoading={
                    isParticipantsLoading || isParticipantChanging || isLoading
                  }
                  participants={participants}
                />
              )}
            </div>
          </div>
        </div>

        {isPartyOfUser || isParticipant ? (
          <div className="fixed bottom-24 right-6">
            <GradientContainer styles="rounded-full text-white">
              <button className="p-2" onClick={() => handleChatRedirect()}>
                <IoChatbubble size={18} />
              </button>
            </GradientContainer>
          </div>
        ) : (
          <div className="fixed bottom-24 right-6 opacity-50">
            <GradientContainer styles="rounded-full text-white">
              <div className="p-2">
                <IoChatbubble size={18} />
              </div>
            </GradientContainer>
          </div>
        )}
      </>
    </PullToRefresh>
  );
}
