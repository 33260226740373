import { Outlet } from "react-router-dom";
import { LayoutProvider } from "./providers/layoutProvider";
import { PartiesProvider } from "./providers/partiesProvider";
import { useAccount } from "./providers/accountProvider";
import { LoadingOverlay } from "./components/ui/LoadingOverlay";

export default function App() {
  const { account, isAccountLoading } = useAccount();

  if (isAccountLoading) {
    return <LoadingOverlay description={null} />;
  }

  if (!isAccountLoading && !account) {
    return <>Account not found, please contact support!</>;
  }

  return (
    <PartiesProvider>
      <LayoutProvider>
        <Outlet />
      </LayoutProvider>
    </PartiesProvider>
  );
}
