import { Party, Profile } from "../../types";
import { GradientBorder } from "../ui/GradientContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faUserGroup,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useAccount } from "../../providers/accountProvider";
import { useParty, usePartyParticipants } from "../../providers/partyProvider";
import ParticipantsSheet from "../ParticipantsSheet";
import {
  ArrowRightEndOnRectangleIcon,
  UserGroupIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import { LoadingSpinner } from "../ui/LoadingOverlay";

export function ParticipationControls({
  party,
  isOwner,
  isParticipant,
  isPartyActive,
  hasEnded,
  onPartyJoin,
  onLeaveParty,
  isLoading,
}: {
  party: Party;
  isOwner: boolean;
  isParticipant: boolean;
  isPartyActive: boolean;
  hasEnded: boolean;
  onPartyJoin(party: Party): void;
  onLeaveParty(party: Party): void;
  isLoading?: boolean;
}) {
  if (isOwner) {
    return <ParticipantsControls hasEnded={hasEnded} />;
  }

  if (isParticipant) {
    return (
      <button
        disabled={!isPartyActive || hasEnded}
        onClick={() => onLeaveParty(party)}
        className="w-full"
      >
        <GradientBorder
          disabled={hasEnded || !isPartyActive}
          from="#00FFAA"
          via={["#4579F5"]}
          to={"#9C42F5"}
        >
          <div className="bg-tic-taps-secondary-grey px-4 py-1 w-full flex items-center justify-center">
            {isLoading ? (
              <span className="pt-1">
                <LoadingSpinner />
              </span>
            ) : (
              <>
                <ArrowRightEndOnRectangleIcon
                  className={`${
                    hasEnded ? "text-gray-500" : "text-white"
                  } size-6 `}
                />
                <p
                  className={`${
                    hasEnded ? "text-gray-500" : "text-white"
                  } self-center font-semibold`}
                >
                  Leave
                </p>
              </>
            )}
          </div>
        </GradientBorder>
      </button>
    );
  }

  return (
    <button
      disabled={!isPartyActive || hasEnded}
      onClick={() => onPartyJoin(party)}
      className="w-full"
    >
      <GradientBorder
        disabled={hasEnded || !isPartyActive}
        from={"#00FFAA"}
        via={["#4579F5"]}
        to={"#9C42F5"}
      >
        <div className="bg-tic-taps-secondary-grey px-4 py-1 w-full flex items-center justify-center space-x-2">
          {isLoading ? (
            <span className="pt-1">
              <LoadingSpinner />
            </span>
          ) : (
            <>
              <UserGroupIcon
                className={`${
                  hasEnded ? "text-gray-500" : "text-white"
                } size-6`}
              />
              <p
                className={`${
                  hasEnded ? "text-gray-500" : "text-white"
                } self-center font-semibold`}
              >
                Join
              </p>
            </>
          )}
        </div>
      </GradientBorder>
    </button>
  );
}

function ParticipantsControls({
  hasEnded: hasEndedProps,
}: {
  hasEnded: boolean;
}) {
  const hasEnded = true;
  const { followers, isFollowersLoading } = useAccount();
  const { participants } = usePartyParticipants();
  const [participantsManagementOpen, setParticipantsManagementOpen] =
    useState(false);

  function handleFollowersAndParticipantsOpen() {
    setParticipantsManagementOpen(true);
  }

  return (
    <>
      <button
        disabled={hasEnded}
        onClick={() => handleFollowersAndParticipantsOpen()}
        className="w-full"
      >
        <GradientBorder
          disabled={hasEnded}
          from="#00FFAA"
          via={["#4579F5"]}
          to={"#9C42F5"}
        >
          <div className="bg-tic-taps-secondary-grey px-4 py-1 w-full flex items-center justify-center space-x-2">
            <UserPlusIcon
              className={`${hasEnded ? "text-gray-500" : "text-white"} size-6`}
            />
            <p
              className={`${
                hasEnded ? "text-gray-500" : "text-white"
              }  self-center font-semibold`}
            >
              Invite
            </p>
          </div>
        </GradientBorder>
      </button>

      <ParticipantsSheet
        isLoading={isFollowersLoading}
        isOpen={participantsManagementOpen}
        participants={participants}
        followers={followers ?? []}
        addParticipant={(p: Profile) => console.log(p)}
        removeParticipant={(p: Profile) => console.log(p)}
        onClose={() => setParticipantsManagementOpen(false)}
      />
    </>
  );
}
