import { useState } from "react";
import { useAuth } from "../../providers/authProvider";
import useTTRouter from "../../router/hooks/useTTRouter";
import { useNotifications } from "../../providers/notificationProvider";
import { App as CapacitorApp } from "@capacitor/app";
import { object, string } from "yup";
import clsx from "clsx";
import { FullScreenLoadingOverlay } from "src/components/ui/LoadingOverlay";

const schema = object().shape({
  email: string().required().email(),
});

export default function Login() {
  const [email, setEmail] = useState("");
  const { otpLogin, isAuthLoading } = useAuth();
  const { logWarning } = useNotifications();
  const { navigateToLogin, navigateToHome, navigateToParty } = useTTRouter();

  const [partyRedirect, setPartyRedirect] = useState<string | null>(null);

  CapacitorApp.addListener("appUrlOpen", (data: any) => {
    if (data.url.includes("tictaps://")) {
      const partyCode = getUrlValueAfterParty(data.url);
      setPartyRedirect(partyCode);
    }
  });

  function getUrlValueAfterParty(url: string) {
    const keyword = "party/";
    const startIndex = url.indexOf(keyword);
    if (startIndex !== -1) {
      return url.substring(startIndex + keyword.length);
    }
    return null;
  }

  const isValidEmail = schema.isValidSync({ email: email ?? "" });

  function login() {
    if (!isValidEmail) {
      logWarning("Email is invalid", "Please enter a valid email");
      return;
    }

    if (!email) {
      logWarning("Email is required", "Please enter an email");
      return;
    }
    otpLogin(
      email,
      async () => {
        if (partyRedirect) {
          navigateToParty(partyRedirect);
        } else {
          navigateToHome();
        }
      },
      (message: string) => {
        console.error(message);
        navigateToLogin();
      }
    );
  }

  return (
    <>
      <FullScreenLoadingOverlay
        display={isAuthLoading}
        description="Logging in.."
      />
      <div className="w-full h-full flex flex-col items-center gap-y-8 px-5 b pt-20">
        <div className="bg-gradient-glow shadow-glow rounded-full w-32 h-32">
          <img src="/loginLogo.webp" alt="logo" className="w-full h-full" />
        </div>
        <div className="text-white text-2xl">Login</div>

        <div className="border-hairline border-[#2e3138] rounded-2xl p-4 w-full md:w-1/2 lg:w-1/3 h-fit  shadow-xl flex flex-col gap-y-4 to-[#181b21] bg-gradient-to-br from-[#31353b]">
          <div className="py-2">
            <div className="text-gray-400 text-sm pb-1">Email</div>
            <input
              value={email}
              placeholder="Enter an email"
              onChange={(e) => setEmail(e.target.value)}
              className={clsx(
                "w-full focus:outline-hairline  rounded-2xl px-4 py-4 border-hairline bg-[#2a2d34]  text-[#7d7f83] placeholder:text-[#7d7f83]"
              )}
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect="off"
            />
          </div>

          <button
            onClick={() => login()}
            className="text-white font-semibold hover:opacity-90 active:opacity-90 text-center rounded-xl bg-gradient-to-br from-[#00FFAA] via-[#4579F5] to-[#9C42F5] p-4"
          >
            Sign in/Register
          </button>
        </div>
      </div>
    </>
  );
}
