import { useState } from "react";
import GradientContainer from "./ui/GradientContainer";
import { Sheet } from "react-modal-sheet";
import { RewardsSpinner } from "./RewardsSpinner";

export function ClaimRewardsCard() {
  const [isRewardsOpen, setIsRewardsOpen] = useState(false);
  function handleRewardsClose() {
    setIsRewardsOpen(false);
  }

  function handleRewardsOpen() {
    setIsRewardsOpen(true);
  }

  return (
    <>
      <div className="flex backdrop-blur-sm bg-gradient-to-br to-white/10 via-[#34414d]/10 from-[#34414d]/10 flex-row items-center justify-between border-hairline border-[#34414d] rounded-xl p-3">
        <div className="flex flex-col gap-x-2">
          <p className="text-white font-semibold text-xl">
            Get free TicTaps Tokens now!
          </p>
        </div>
        <GradientContainer styles="rounded-xl">
          <button
            onClick={() => handleRewardsOpen()}
            className="text-white font-semibold text-xs px-4 py-2"
          >
            Claim
          </button>
        </GradientContainer>
      </div>

      <Sheet isOpen={isRewardsOpen} onClose={handleRewardsClose}>
        <Sheet.Container>
          <Sheet.Header className="bg-tic-taps-grey" />
          <Sheet.Content className="bg-tic-taps-grey p-0">
            <RewardsSpinner onComplete={handleRewardsClose} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop className="bg-tic-taps-grey" />
      </Sheet>
    </>
  );
}
