import { Transition } from "@headlessui/react";
import LoadingRing from "./loadingRing";

export function FullScreenLoadingOverlay({
  display,
  description,
}: {
  display: boolean;
  description: string;
}) {
  return (
    <Transition show={display}>
      <div className="h-screen flex items-center justify-center w-full z-50 top-0 right-0 absolute transition duration-150 ease-in data-[closed]:opacity-0">
        <LoadingOverlay description={description} />
      </div>
    </Transition>
  );
}

export function LoadingOverlay({
  description,
}: {
  description: string | null;
}) {
  return (
    <div className="absolute top-0 right-0 w-full h-full bg-tic-taps-grey flex items-center justify-center flex-col space-y-4">
      <div className="bg-gradient-glow shadow-glow rounded-full h-44 w-44">
        <img src="/loginLogo.webp" alt="logo" className="w-full h-full" />
      </div>
      <div className="flex flex-col items-center space-y-2">
        <p className="text-white font-bold">{description}</p>
        <LoadingSpinner />
      </div>
    </div>
  );
}

export function LoadingSpinner() {
  return (
    <div role="status">
      <LoadingRing />
      <span className="sr-only">Loading...</span>
    </div>
  );
}
