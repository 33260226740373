import { Sheet } from "react-modal-sheet";
interface TutorialSheetProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  picture: string;
}

export const TutorialSheet: React.FC<TutorialSheetProps> = ({
  isOpen,
  onClose,
  title,
  description,
  picture,
}) => {
  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      detent="content-height"
      className="relative backdrop-blur-sm"
    >
      <Sheet.Container className="bg-custom-zinc min-h-[600px] fixed flex items-end z-50 bg-transparent">
        <Sheet.Header className="bg-custom-zinc text-white text-center font-light rounded-t-xl">
          <h1 className="text-2xl font-bold p-4">Tutorial: {title}</h1>
        </Sheet.Header>
        <Sheet.Content className=" text-white p-6 flex flex-col items-center bg-opacity-80 backdrop-blur-sm">
          <div className="w-full flex justify-center mb-6">
            <img
              src={picture}
              alt={title}
              className="rounded-lg shadow-lg max-h-64 w-full object-cover"
            />
          </div>

          <p className="text-lg font-light text-gray-300 tracking-tight text-justify">
            {description}
          </p>

          <button
            onClick={onClose}
            className="mt-6 text-white w-full font-bold bg-gradient-to-br from-[#00FFAA] via-[#4579F5] to-[#9C42F5] px-8 py-3 rounded-lg"
          >
            Close
          </button>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
};
