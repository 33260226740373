import { Dialog } from "@headlessui/react";
import React from "react";
import { GradientBorder } from "./GradientContainer";

interface UnfollowModalProps {
  isOpen: boolean;
  followerName: string;
  onRequestClose: () => void;
  unfollowConfirm?: () => void;
}

interface FollowModalProps {
  isOpen: boolean;
  followerName: string;
  onRequestClose: () => void;
  followConfirm?: () => void;
}

const UnfollowModal: React.FC<UnfollowModalProps> = ({
  isOpen,
  followerName,
  onRequestClose,
  unfollowConfirm,
}) => {
  return (
    <Dialog open={isOpen} onClose={() => {}} className="relative z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-30"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-tic-taps-grey rounded-2xl border border-tic-taps-grey shadow-2xl p-6 w-full max-w-md">
          <Dialog.Title className="text-lg font-semibold mb-4 text-white text-center">
            Unfollow user?
          </Dialog.Title>
          <Dialog.Description className="mb-4 text-white">
            To unfollow{" "}
            <span className="bg-gradient-to-r from-[#FF5E00] to-[#FFAA00] text-transparent bg-clip-text">
              {followerName}
            </span>
            , please click the button below.
          </Dialog.Description>
          <GradientBorder
            padding="p-0.5"
            from="#FF5E00"
            to="#FFAA00"
            via={["#FFAA00"]}
          >
            <button
              onClick={() => {
                if (unfollowConfirm) unfollowConfirm();
              }}
              className="px-5 py-2 w-full rounded-lg transition-colors bg-tic-taps-grey text-white hover:bg-gray-900 hover:shadow-glow"
            >
              Unfollow
            </button>
          </GradientBorder>
          <button
            onClick={() => onRequestClose()}
            className="mt-2 text-gray-500 underline w-full text-center"
          >
            Close
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const FollowModal: React.FC<FollowModalProps> = ({
  isOpen,
  followerName,
  onRequestClose,
  followConfirm,
}) => {
  return (
    <Dialog open={isOpen} onClose={() => {}} className="relative z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-30"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-tic-taps-grey rounded-2xl border border-tic-taps-grey shadow-2xl p-6 w-full max-w-md">
          <Dialog.Title className="text-lg font-semibold mb-4 text-white text-center">
            Follow back user?
          </Dialog.Title>
          <Dialog.Description className="mb-4 text-white">
            To follow{" "}
            <span className="bg-gradient-to-r from-cyan-500 to-purple-500 text-transparent bg-clip-text">
              {followerName}
            </span>
            , please click the button below.
          </Dialog.Description>
          <GradientBorder padding="p-0.5">
            <button
              onClick={() => {
                if (followConfirm) followConfirm();
              }}
              className="px-5 py-2 w-full rounded-lg transition-colors bg-tic-taps-grey text-white hover:bg-gray-900 hover:shadow-glow"
            >
              Follow
            </button>
          </GradientBorder>
          <button
            onClick={() => onRequestClose()}
            className="mt-2 text-gray-500 underline w-full text-center"
          >
            Close
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

interface InstallModalProps {
  isOpen: boolean;
  // onRequestClose: () => void;
  pwaUrl: string;
  onInstallClick?: () => void;
}

const InstallModal: React.FC<InstallModalProps> = ({
  isOpen,
  pwaUrl,
  onInstallClick,
}) => {
  return (
    <Dialog open={isOpen} onClose={() => {}} className="relative z-10">
      <div
        className="fixed inset-0 bg-black bg-opacity-30"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-tic-taps-grey rounded-2xl border border-tic-taps-grey shadow-2xl p-6 w-full max-w-md">
          <Dialog.Title className="text-lg font-semibold mb-4 text-white text-center">
            Install TicTaps
          </Dialog.Title>
          <Dialog.Description className="mb-4 text-white">
            To install our app, please click the button below.
          </Dialog.Description>
          <GradientBorder padding="p-0.5">
            <button
              onClick={() => {
                if (onInstallClick) onInstallClick();
              }}
              className="px-5 py-2 w-full rounded-lg transition-colors bg-tic-taps-grey text-white hover:bg-gray-900 hover:shadow-glow"
            >
              Install TicTaps
            </button>
          </GradientBorder>
          {/* <button
            onClick={onRequestClose}
            className="mt-2 text-gray-500 underline w-full text-left"
          >
            Close
          </button> */}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const LogOutModal: React.FC<{ isOpen: boolean, onClose: () => void, onConfirm: () => void }> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-tic-taps-grey rounded-2xl border border-tic-taps-grey shadow-2xl p-6 w-full max-w-md">
          <Dialog.Title className="text-lg font-semibold mb-4 text-white text-center">
          Are you sure you want to log out?
          </Dialog.Title>
          <Dialog.Description className="mb-4 text-white text-center">
           You can always log back in later 🤗.
          </Dialog.Description>
          <GradientBorder
            padding="p-0.5"
            from="#FF5E00"
            to="#FFAA00"
            via={["#FFAA00"]}
          >
            <button
              onClick={onConfirm} 
              className="px-5 py-2 w-full rounded-lg transition-colors bg-tic-taps-grey text-red-500 hover:bg-gray-900 hover:shadow-glow"
            >
              Log Out
            </button>
          </GradientBorder>
          <button
            onClick={onClose}
            className="mt-2 text-gray-500 underline w-full text-center"
          >
            Cancel
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export { FollowModal, UnfollowModal, InstallModal, LogOutModal };
