import React, { createContext, useContext, ReactNode, useState } from "react";
import { Account, Platform, Profile, ProfileHistory } from "../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import GetAccount from "../api/account/profile/me";
import GetPartiesHistory from "../api/account/profile/getHistory";
import GetFollowers from "../api/account/follow/getFollowers";
import GetBalances from "../api/account/currencies/getBalances";
import AddTicTapsTokens from "../api/account/currencies/addTicTapsTokens";
import { useAuth } from "./authProvider";
import CreatePayment from "../api/account/currencies/createPayment";
import GetFollowedUsers from "../api/account/follow/getFollowedUsers";
import { Currency } from "../types";
import AddTrophies from "../api/account/currencies/addTrophies";
import patchProfile from "src/api/account/profile/patchProfile";

export const currencies: Currency[] = [
  {
    type: "FIAT",
    code: "USD",
    symbol: "$",
  },
  {
    type: "FIAT",
    code: "EUR",
    symbol: "€",
  },
  {
    type: "TICTAPS",
    code: "TTT",
    symbol: "TTT",
  },
  {
    type: "TICTAPS",
    code: "TRY",
    symbol: "TRO",
  },
  {
    type: "CRYPTO",
    code: "BTC",
    symbol: "BTC",
  },
  {
    type: "CRYPTO",
    code: "ETH",
    symbol: "ETH",
  },
];

export const platforms: Platform[] = [];

// Create a context with undefined initial value
const AccountContext = createContext<
  | {
      account: Account | undefined;
      isAccountLoading: boolean;

      profileHistory: ProfileHistory | undefined;
      isProfileHistoryLoading: boolean;

      followers: Profile[] | undefined;
      isFollowersLoading: boolean;

      following: Profile[] | undefined;
      isFollowingLoading: boolean;

      balance: {
        ticTapsTokens: number;
        trophies: number;
        dollars: number;
      };

      isBalanceLoading: boolean;

      addTicTapsTokens: (amount: number) => void;
      addTrophies: (amount: number) => void;
      refreshFollowers: () => void;
      refreshFollowedUser: () => void;
      refreshHistory: () => void;
      startPayment: (data: { amount: number; currency_code: string }) => void;
      claimTicTapsTokensReward: (rewardAmount: number) => void;
      refreshBalances: () => Promise<void>;
      rewardClaimed: boolean;
      refreshAccount: () => void;
      patchProfile: (data: {
        nickname?: string;
        profile_image?: string;
        gamer_tag?: string;
      }) => void;
    }
  | undefined
>(undefined);

export const AccountProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isLoggedIn, isAuthLoading } = useAuth();
  const [rewardClaimed, setRewardClaimed] = useState(() => Math.random() < 0.5);

  const accountQuery = useQuery({
    queryKey: ["account"],
    enabled: !!isLoggedIn && !isAuthLoading,
    queryFn: GetAccount,
  });

  const accountId = accountQuery.data?.id;

  const partiesHistoryQuery = useQuery({
    queryKey: ["partiesHistory", accountId],
    enabled: !!isLoggedIn && !isAuthLoading,
    queryFn: GetPartiesHistory,
  });

  const followersQuery = useQuery({
    queryKey: ["followers", accountId],
    enabled: !!isLoggedIn && !isAuthLoading,
    queryFn: async () => {
      if (accountId) {
        return await GetFollowers(accountId);
      }
      return [];
    },
  });

  const followedUserQuery = useQuery({
    queryKey: ["following", accountId],
    enabled: !!isLoggedIn && !isAuthLoading && !!!accountQuery.isLoading,
    queryFn: async () => {
      if (!accountQuery.data) return [];
      const account = accountQuery.data;
      return await GetFollowedUsers(account.id);
    },
  });

  const balancesQuery = useQuery({
    queryKey: ["currencies", accountId],
    enabled: !!isLoggedIn && !isAuthLoading,
    refetchOnMount: true,
    queryFn: async () => await GetBalances(),
  });

  const addTicTapsTokens = useMutation({
    mutationFn: async (amount: number) => {
      await AddTicTapsTokens(amount);
    },
    onSuccess: () => {
      balancesQuery.refetch();
    },
  });

  const addTrophiesMutation = useMutation({
    mutationFn: async (amount: number) => {
      await AddTrophies(amount);
    },
    onSuccess: () => {
      balancesQuery.refetch();
    },
  });

  const patchProfileMutation = useMutation({
    mutationFn: async (data: {
      nickname?: string;
      profile_image?: string;
      gamer_tag?: string;
    }) => {
      if (!accountId) {
        throw new Error("Account ID is not available.");
      }
      return await patchProfile(accountId, data);
    },
    onSuccess: () => {
      accountQuery.refetch();
    },
  });

  function updateProfile(data: {
    nickname?: string;
    profile_image?: string;
    gamer_tag?: string;
  }) {
    patchProfileMutation.mutate(data);
  }

  async function claimTicTapsTokensReward(rewardAmount: number) {
    addTicTapsTokens.mutate(rewardAmount);
    await refreshBalances();
    setRewardClaimed(true);
  }

  const startPaymentMutation = useMutation({
    mutationFn: async (data: { amount: number; currency_code: string }) => {
      const url = await CreatePayment(data.amount, data.currency_code);
      window.location.assign(url);
    },
  });

  const ticTapsTokens =
    balancesQuery.data?.find(
      (balance: { code: string; type: string }) =>
        balance.code === "TTT" && balance.type === "TICTAPS"
    )?.amount ?? 0;

  const trophies =
    balancesQuery.data?.find(
      (balance: { code: string; type: string }) =>
        balance.code === "TRY" && balance.type === "TICTAPS"
    )?.amount ?? 0;

  const dollars =
    balancesQuery.data?.find(
      (balance: { code: string; type: string }) =>
        balance.code === "USD" && balance.type === "FIAT"
    )?.amount ?? 0;

  function refreshHistory() {
    partiesHistoryQuery.refetch();
  }

  async function refreshBalances() {
    await balancesQuery.refetch();
  }
  function refreshAccount() {
    accountQuery.refetch();
    partiesHistoryQuery.refetch();
    balancesQuery.refetch();
    followedUserQuery.refetch();
    followersQuery.refetch();
  }

  return (
    <AccountContext.Provider
      value={{
        followers: followersQuery.data,
        isFollowersLoading: followersQuery.isLoading,

        following: followedUserQuery.data,
        isFollowingLoading: followedUserQuery.isLoading,

        account: accountQuery.data,
        isAccountLoading: accountQuery.isLoading,

        profileHistory: partiesHistoryQuery.data,
        isProfileHistoryLoading: partiesHistoryQuery.isLoading,

        balance: {
          ticTapsTokens,
          trophies,
          dollars,
        },
        isBalanceLoading: balancesQuery.isLoading || balancesQuery.isFetching,

        addTicTapsTokens: addTicTapsTokens.mutate,
        addTrophies: addTrophiesMutation.mutate,
        refreshFollowers: () => followersQuery.refetch(),
        refreshFollowedUser: () => followersQuery.refetch(),
        refreshHistory,
        startPayment: startPaymentMutation.mutateAsync,

        rewardClaimed,
        claimTicTapsTokensReward: claimTicTapsTokensReward,
        refreshBalances,
        refreshAccount,
        patchProfile: updateProfile,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export function useAccount() {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error("useAccount must be used within a AccountProvider");
  }
  return context;
}
