import { useState } from "react";
import {
  useNoBackground,
  useSecondaryHeader,
} from "../../providers/layoutProvider";
import { useAccount } from "../../providers/accountProvider";
import { BackspaceIcon } from "@heroicons/react/24/solid";

export function LoadMoneyPage() {
  useSecondaryHeader("Onboard Money");
  useNoBackground();

  const [value, setValue] = useState<string>("20");

  const { startPayment } = useAccount();

  return (
    <div className="flex flex-col items-center pb-40">
      <div className="w-full  flex justify-center h-48 items-center transition-all">
        <p className="text-8xl text-white">{value}</p>
      </div>
      <div className="w-full p-2">
        <Keyboard
          removeCharacter={() => setValue((prev) => prev.slice(0, -1))}
          onType={(v: string) => setValue((prev) => prev + v)}
        />
      </div>
      <div className="w-full p-2 z-[10]">
        <button
          onClick={() =>
            startPayment({
              amount: Number(value),
              currency_code: "USD",
            })
          }
          className="bg-gray-200 w-full rounded-full active:bg-white text-2xl font-semibold p-2"
        >
          Onboard Money
        </button>
      </div>
    </div>
  );
}

function Keyboard({
  onType,
  removeCharacter: clearCharacter,
}: {
  removeCharacter: () => void;
  onType: (value: string) => void;
}) {
  return (
    <div className="flex flex-col gap-y-2 ">
      <div className="flex w-full  justify-between gap-x-2 z-[10]">
        <button
          onClick={() => onType("1")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          1
        </button>
        <button
          onClick={() => onType("2")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          2
        </button>{" "}
        <button
          onClick={() => onType("3")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          3
        </button>
      </div>
      <div className="flex w-full  justify-between gap-x-2 z-[10]">
        <button
          onClick={() => onType("4")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          4
        </button>{" "}
        <button
          onClick={() => onType("5")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          5
        </button>{" "}
        <button
          onClick={() => onType("6")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          6
        </button>
      </div>
      <div className="flex w-full  justify-between gap-x-2 z-[10]">
        <button
          onClick={() => onType("7")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          7
        </button>{" "}
        <button
          onClick={() => onType("8")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          8
        </button>{" "}
        <button
          onClick={() => onType("9")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          9
        </button>
      </div>
      <div className="flex w-full  justify-between gap-x-2 z-[10]">
        <div
          onClick={() => onType("8")}
          className="active:bg-gray-500 items-center justify-center w-full rounded-lg flex h-24 text-gray-400"
        ></div>
        <button
          onClick={() => onType("0")}
          className="active:bg-gray-500 transition-all items-center justify-center w-full  rounded-lg flex h-24 text-gray-200 font-semibold text-4xl"
        >
          0
        </button>
        <div
          onClick={clearCharacter}
          className="active:bg-gray-500 items-center justify-center w-full rounded-lg flex h-24 text-gray-400"
        >
          <BackspaceIcon className="h-10 fill-white" />
        </div>
      </div>
    </div>
  );
}
