import { useNavigate } from "react-router-dom";
const base = "/app";

export const ttRoutes = {
  home: () => `${base}/home`,
  createParty: () => `${base}/party/create`,
  partyChat: (chatId?: string) => `${base}/party/chat/${chatId ?? ":chatId"}`,
  game: (gameId?: string, partyId?: string) =>
    `${base}/party/${partyId ?? ":partyId"}/game/${gameId ?? ":gameId"}`,
  login: () => `${base}/auth/login`,
  followers: () => `${base}/followers`,
  following: () => `${base}/following`,
  userHistory: (userId?: string) => `${base}/history/${userId ?? ":userId"}`,
  party: (partyId?: string) => `${base}/party/${partyId ?? ":partyId"}`,
  myProfile: () => `${base}/profile`,
  //Dirty fckn hack remove
  userProfile: (userId?: string) =>
    `${base}/profile/user/${userId ?? ":userId"}`,
  leaderboard: (partyId?: string) =>
    `${base}/leaderboard/${partyId ?? ":partyId"}`,
  paymentReturn: () => `${base}/payment-return`,
  loadMoney: () => `${base}/payment/loadMoney`,
  logout: () => `${base}/logout`,
  test: () => `${base}/test`,
  settings: () => `${base}/settings`,
};

export default function useTTRouter() {
  const navigate = useNavigate();

  function navigateToHome() {
    navigate(ttRoutes.home());
  }

  function navigateToTest() {
    navigate(ttRoutes.test());
  }

  function navigateToSettings() {
    navigate(ttRoutes.settings());
  }

  function navigateToGame(gameId: string, partyId: string) {
    navigate(ttRoutes.game(gameId, partyId));
  }

  function navigateToLogin() {
    navigate(ttRoutes.login());
  }

  function navigateToFollowing() {
    navigate(ttRoutes.following());
  }

  function navigateToParty(partyId: string) {
    navigate(ttRoutes.party(partyId));
  }

  function navigateToPartyChat(chatId: string) {
    navigate(ttRoutes.partyChat(chatId));
  }

  function navigateToMyProfile() {
    navigate(ttRoutes.myProfile());
  }

  function navigateToUserProfile(userId: string) {
    navigate(ttRoutes.userProfile(userId));
  }

  function navigateToLeaderboard(partyId: string) {
    navigate(ttRoutes.leaderboard(partyId));
  }

  function navigateToPartyCreation() {
    navigate(ttRoutes.createParty());
  }

  function navigateToUserHistory(userId: string) {
    navigate(ttRoutes.userHistory(userId));
  }

  function navigateToFollowers() {
    navigate(ttRoutes.followers());
  }

  function navigateBack() {
    navigate(-1);
  }

  function navigateToOnboardMoney() {
    navigate(ttRoutes.loadMoney());
  }

  function navigateToLogout() {
    navigate(ttRoutes.logout());
  }

  return {
    navigateToPartyChat,
    navigateToHome,
    navigateToGame,
    navigateToLogin,
    navigateToParty,
    navigateToPartyCreation,
    navigateToMyProfile,
    navigateToUserProfile,
    navigateToUserHistory,
    navigateToLeaderboard,
    navigateToFollowers,
    navigateBack,
    navigateToOnboardMoney,
    navigateToFollowing,
    navigateToLogout,
    navigateToTest,
    navigateToSettings,
  };
}
