import { JoinPartyByCodeCard } from "src/components/JoinPartyCard";
import useTTRouter from "../../router/hooks/useTTRouter";
import { Party } from "../../types";
import { useBubblesBackground } from "../../providers/layoutProvider";
import { useParties } from "../../providers/partiesProvider";
import { ClaimRewardsCard } from "../../components/RewardCard";
import PullToRefresh from "react-simple-pull-to-refresh";
import { EditablePartiesList } from "src/components/EditablePartiesList";
import { FixedPartiesHorizontalList } from "src/components/FixedPartiesHorizontalList";
import { useAccount } from "src/providers/accountProvider";

export default function Main() {
  useBubblesBackground();

  const { publicParties, privateParties, isPartiesLoading, refreshParties } =
    useParties();

  const { navigateToParty } = useTTRouter();

  const { refreshBalances } = useAccount();

  function handlePartySelection(party: Party) {
    navigateToParty(party.id);
  }

  async function handleRefresh() {
    refreshParties();
    refreshBalances();
  }

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <div className="flex flex-col gap-y-2 w-full md:w-2/3 lg:w-1/2 h-fit pb-60">
        <div className="flex flex-col px-2 gap-y-2">
          <ClaimRewardsCard />
          <JoinPartyByCodeCard />
        </div>

        <FixedPartiesHorizontalList
          title={"Public Parties 🥳"}
          onPartyClick={(party: Party) => handlePartySelection(party)}
          parties={publicParties}
          isLoading={isPartiesLoading}
        />

        <EditablePartiesList
          title={"Private Parties 🎉"}
          onPartyClick={(party: Party) => handlePartySelection(party)}
          parties={privateParties}
          isLoading={isPartiesLoading}
        />
      </div>
    </PullToRefresh>
  );
}
